export default {
	computed: {
		isCryptoCurrency() {
			if (this.$cookie.get('currency_type')) {
				const currencyType = parseFloat(this.$cookie.get('currency_type'))
				// 1 is fiat currency, 2 is crypto currency
				return currencyType === 2
			}
			return false;
		}
	},
	methods: {
		decimalRule(v) {
			if (!v) return
			// Convert number to string
			const numStr = v?.replace(/,/g, '')?.toString()
			// Find the position of the decimal point
			const decimalIndex = numStr?.indexOf('.')

			// If there's no decimal point, return false
			if (decimalIndex === -1) {
				return true
			}
			// Check the length of the part after the decimal point
			const decimalPlaces = numStr.length - decimalIndex - 1
			// Return true if exactly one decimal place, otherwise false
			return decimalPlaces >= 1 && decimalPlaces <= 2
		},
		decimal6Rule(v) {
			if (!v) return
			// Convert number to string
			const numStr = v?.replace(/,/g, '')?.toString()
			// Find the position of the decimal point
			const decimalIndex = numStr?.indexOf('.')

			// If there's no decimal point, return false
			if (decimalIndex === -1) {
				return true
			}
			// Check the length of the part after the decimal point
			const decimalPlaces = numStr.length - decimalIndex - 1
			// Return true if exactly one decimal place, otherwise false
			return decimalPlaces >= 1 && decimalPlaces <= 6
		},
		unFormatNumber(number) {
			return number?.toString().replace(/,/g, '')
		}
	}
}